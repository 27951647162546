<template>
  <div class="character-card-small d-flex p-2">
    <router-link :to="{ name: 'character-profile-new', params: { slug: character.slug } }" class="clickable">
      <img
        loading="lazy"
        v-image
        :src="resizeUpload(get(character, 'info.cropProfilePicture', '/empty.png'), '80x80')"
      />
    </router-link>

    <div class="rest-info ml-3 flex-grow-1 char-info" style="">
      <div class="name d-flex align-items-center justify-content-start">
        <router-link
          class="routerlink clickable"
          :to="{
            name: 'character-profile-new',
            params: { slug: character.slug },
          }"
        >
          <span v-if="character.privacy === 'M'">🔒&nbsp;</span><span v-if="character.privacy === 'U'">🔗&nbsp;</span
          >{{ character.info.name }}
        </router-link>
      </div>
      <div class="description" v-html="characterDescriptionTruncated"></div>
      <div class="actions mt-2 w-75 d-flex justify-content-start">
        <reaction
          :reactions="reactions"
          :user-reaction="userReaction"
          @changed="(reaction) => reactionChanged(reaction.key, character, reaction.isInstant)"
        >
          <ion-button color="transparent" class="inline-button icon-button">
            <div
              class="d-flex align-items-center justify-content-center text-primary"
              :class="{
                'text-secondary': !!userReaction && userReaction.reaction,
              }"
            >
              <i class="ti-thumb-up mr-2" />
              <span class="reaction-count">{{ reactions?.total_count || 0 }}</span>
            </div>
          </ion-button>
        </reaction>

        <router-link
          :to="{
            name: 'character-profile-new',
            params: { slug: character.slug },
            query: { scrollTo: 'comments' },
          }"
        >
          <ion-button v-if="showCommentCounts" color="transparent" class="inline-button icon-button ml-2">
            <div class="d-flex align-items-center justify-content-center">
              <i class="ti-comment mr-2 icon" />
              <span class="reaction-count primary-color">{{ commentsCount || 0 }}</span>
            </div>
          </ion-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Action } from 's-vuex-class';
import Reaction from './Reaction/index.vue';
import { Character } from '@/shared/types/static-types';
import { truncateEasy, stripHtmlTags } from '@/shared/utils/string';
import namespace from '@/shared/store/namespace';
import { authStore } from '@/shared/pinia-store/auth';
import { resizeUpload } from '@/shared/utils/upload';

@Options({
  name: 'CharacterCardSmall',
  components: {
    Reaction,
  },
})
export default class CharacterCardSmall extends Vue {
  @Prop({ default: () => ({}) }) character!: Character;
  @Prop({ default: false }) showCommentCounts!: boolean;
  @Prop({ required: false }) commentsCount!: number;

  resizeUpload = resizeUpload;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  @Action('react', { namespace: namespace.CharactersModule })
  public react!: any;

  get = get;

  public async reactionChanged(reaction: string, char: any, isInstant = false) {
    const reactionResp = await this.react({
      reaction,
      character: char,
      isInstant,
    });
    this.$emit('afterReaction', reactionResp);
  }

  public get userReaction() {
    return this.character.user_reaction;
  }

  public edit() {
    const router = useRouter();
    router.push(`/create/${this.character.id}`);
  }

  public get isOwner() {
    try {
      return this.character.author!.username === this.user.username;
    } catch (error) {
      return false;
    }
  }

  public get characterDescriptionTruncated() {
    return truncateEasy(stripHtmlTags(this.character.info.description || ''), 114);
  }

  public get reactions() {
    const { reaction_counts } = this.character || {};
    return reaction_counts;
  }

  public requestLoadMore(ev: CustomEvent) {
    (ev?.target as any).complete();
  }
}
</script>

<style lang="sass" scoped>
.icon
  font-size: 16px
.actions
  max-width: 200px
  .inline-button
    &:not(:first-of-type)
      margin: 0 5px
.edit-button
  color: #7f10b3
  font-size: 14px
  font-weight: bold
.character-card-small
  img
    width: 76px
    height: 76px
    border-radius: 40px
    border: solid gray 0.1px
    object-fit: cover
  .name
    font-size: 18px
    font-weight: bold
  .description
    font-size: 13px
  .icon-button
    color: #ae38e5
    font-size: 20px
    cursor: pointer
    .d-flex
      font-size: 20px
  .icon-button:hover
    opacity: 0.7
  .reaction-count
    font-size: 18px
    font-weight: bold
.primary-color
  color: #7f10b3
.secondary-color
  color: #3dc2ff
.routerlink
  color: #214163
.char-info
  width: calc(100% - 93px)
</style>
